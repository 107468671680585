// Modal Contact Form

#contactModal {

  .modal-dialog {
    max-width: 500px;
  }

  .modal-title {
    align-items: center;
    display: flex;
    font-size: 1.54rem;
  }
  .modal-body {
    padding: 1rem 0;
  }

  .body-content {
    padding: 15px;
  }

  .modal-footer {
    padding: 1rem 30px 0;

    p {
      font-size: 0.7rem;
    }
  }
}
