//
// Header SASS
// --------------------------------------------------
// Author: Strange Animals <info@strangeanimals.com.au>

header {
  background-color: transparent;
  left: auto;
  position: absolute;
  right: auto;
  top: 0;
  width: 100%;
  z-index: 1;

  &.general {
    background-color: $brand-purple;
  }

  .navbar {
    background-color: transparent !important;
    padding: 8% 0 0;
  }

  .navbar-brand {
    background-color: transparent;
    margin: auto;
    padding: 0;
    position: relative;

    svg {
      height: auto;
      max-width: 100%;
      top: 0px;
      left: auto;

    }
  }
}


// Large phone >= 576px
@include media-breakpoint-down(sm) {

  header {
    .navbar {
      .navbar-brand {
        svg {
            height: 300px;
            width: 300px;
        }
      }
    }

    .feature-content {
      bottom: 70px;
    }
  }
}


// Tablet portrait >= 768px
@include media-breakpoint-down(md) {

  header {
    .navbar-brand {
      svg {
        height: 320px;
      }
    }

  }
}

// Tablet landscape >= 992px
@include media-breakpoint-down(lg) {

  header {
    .navbar {
      background-color: $white;

      .navbar-brand img,
      .navbar-brand svg {
        height: 520px;

      }
    }
  }
}
@include media-breakpoint-up(lg) {

  header {
    .navbar {
      background-color: $white;

      .navbar-brand img,
      .navbar-brand svg {
        height: 520px;
      }
    }
  }
}

// Desktop >= 1200px
@include media-breakpoint-up(xl) {

}

// Large desktop >= 1440px
@include media-breakpoint-up(xxl) {
}

// Huge desktop >= 1680px
@include media-breakpoint-up(xxxl) {
}
