//
// Custom Theme for Etchell Capital
// --------------------------------------------------
// Author: Simplisite Business Solutions <info@simplisite.com.au>

// Basic Colors
$white: #ffffff;
$black: #000000;

// Grayscale
$grey-dark: #332d2d;
$grey-light: #929292;

// Primary Brand Colors
$brand-purple: #1E2549;
$brand-brown: #955C36;
$brand-grey-dark: $grey-dark;
$brand-grey-light: $grey-light;

//
// Modules
// --------------------------------------------------

// Header

// $header-font-color: $brand-purple;
// $header-bg: $white;

// Buttons

$btn-primary-color: $white;
$btn-primary-bg: $brand-brown;
$btn-primary-border: $brand-brown;

// Footer

$footer-link-color: $white;
$footer-bg: $brand-purple;

//
// Page
// --------------------------------------------------
