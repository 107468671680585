//
// Global Styling
// --------------------------------------------------


body {
  align-items: center;
  flex-direction: column;
  font-weight: 400;
  height: 100%;
  min-height: 100vh;
  color: $brand-grey-light;

}

html,
body {
  -webkit-text-size-adjust: 100%;
  background-color: $body-bg;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 18px;
  letter-spacing: 0.065rem;
  width: 100%;
}

.main-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-bottom: -15px;
  margin-top: 0;
  overflow: hidden;
  width: 100%;

  &.general {
    padding-top: 150px;
  }
}

body {
  // min-height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
  // height: 100%;

  &.CricketPortal .main {
    padding-top: 50px;

    .container {
      padding-top: 50px;
    }
  }
}

.inline-block
{
  display:inline-block;
  *display:inline;
  zoom:1;
}

section {
  padding:60px 15px 60px 15px;
}

.email-feedback {
  padding: 100px 0 200px 0;
}

/* Small Devices, Tablets */
@media only screen and (min-width: 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width: 990px) {

  .container {
    max-width: 950px;
  }

  .main {
    margin-top: 155px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 480px) {
}




a, a:hover, a:focus {
  text-decoration: none;
}


/* Small Devices, Tablets */
@media only screen and (min-width: 480px) {

}
