//
// Footer SASS
// --------------------------------------------------
// Author: Strange Animals <info@strangeanimals.com.au>

footer {
  background-color:$footer-bg;
  width: 100%;
  color: $white;
  text-align: center;
  overflow: hidden;

  .nav-footer {
    border-bottom: 9px solid $brand-brown;
    padding-top: 60px;
  }

  .ec-logo {
    margin-top: 60px;
    svg {
      height: 200px;
			max-width: 100%;
    }
    padding-bottom: 40px;
  }

	a {
  	display:block;
  	color:$footer-link-color;
  	&:hover {color:$footer-link-color;}
  	&:active {
    	position:relative;
    	top:1px;
  	}
  	&.sublink {
    	color:$white;
    	font-weight:100;
    	font-size:0.85rem;
  	}
  }

  p {
    color: $white;
    font-size:0.75rem;
  }
}
