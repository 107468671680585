//
// Homepage SASS
// --------------------------------------------------
// Author: Strange Animals <info@strangeanimals.com.au>


.page-home {



}

section {
  position: relative;
}

.feature-content {
  bottom: 70px;
  color: $white;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  width: 320px;
  z-index: 2;

  h1 {
    font-size: 1.1rem;
    margin-bottom: 60px;
  }
}

.feature-panel {
  background-color: $black;
  font-size: 10px;
  height: 100vh;
  margin-bottom: -2px; // Fix rounding issues
  margin-top: 0; // Behind navigation
  min-height: 400px;
  overflow: hidden;
  padding: 0;
  perspective: 2000px;
  position: relative;
  width: 100%;

  h1 {
    color: $white;
    font-size: 1.7rem;
    margin: 0 auto;
    max-width: 580px;
    padding: 0 15px 30px 15px;
    text-shadow: 1px 1px 5px #000;
  }

  // Sets container height
  &::before {
    content: '';
    display: block;
    margin-top: 0;
    padding-bottom: percentage(5/4);
    pointer-events: none;
    position: relative;
    z-index: 1;
  }
}

.top-line {
  border-top: 1px solid $brand-brown;
  height: 1px;
  margin: 20px auto 0 auto;
  padding: 40px 0;
  width: 150px;
}

.tri-bottom,
.tri-top {
  left: 0;
  margin-bottom: 60px;
  position: absolute;
  top: -2px;
  margin-top: -1px;
  transform: rotate(180deg);
  width: 100%;

  svg {
    height: auto;
    width: 110vw;
    height: 7.5vw;
    max-width: 110%;
    vertical-align: bottom;
  }
}

.tri-bottom {
  bottom: -3px;
  margin-bottom: 0;
  top: auto;
  left: 0;
  transform: scaleX(-1);
  z-index: 1000;
  max-width: 100%;
}

.feature-panel__background {
  background: $black;
  background-position: -50% 50%;
  display: block;
  height: 100%;
  left: 0;
  min-height: 400px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  .slick-slide {
    height: 100vh;
    min-height: 400px;
  }
  .home-slider {

  }
  .banner-item {
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
    height: 100%;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    opacity: .9;
    position: absolute;
    top: 0;
    width: 100%;

    &.single {
      display:block;
    }
  }
}

.about-panel {
  padding-bottom: 100px;
}


@media (min-width: 768px) {

  .feature-panel {
    h1 {
      font-size: 1.2rem;
      padding: 10px 15px 50px 15px;
    }
  }


}
